.delete-confirmation-modal {
    position: fixed;
    bottom: -100%; /* Start off the screen at the bottom */
    left: 50%;
    transform: translateX(-50%);
    background-color:#0b0135;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.5s ease-in-out; /* Smooth transition */
    opacity: 0; /* Initially hidden */
  }
  
  .delete-confirmation-modal.slide-up {
    bottom: 30%; /* Slide up to the center of the screen */
    opacity: 1; /* Fade in */
  }
  
  .modal-content {  
    padding: 55px;
    text-align: center;
    color: #ffff;
  }
  
  .modal-actions {
    margin-top: 20px;
  }
  
  .btn-confirm {
    background-color: #FF3838;
    color: white;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  .btn-cancel {
    background-color: #ccc;
    color: black;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  