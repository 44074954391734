.scorecard-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .scorecard-stats {
    flex: 1;
    padding-right: 50px;
  }
  
  .scorecard-stats table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .scorecard-stats th,
  .scorecard-stats td {
    padding: 10px 20px;
    text-align: left;
    font-size: 18px;
  }
  
  .scorecard-stats th {
    font-weight: bold;
  }
  
  .scorecard-stats td {
    color: #333;
  }
  
  .scorecard-chart {
    position: relative;
    width: 300px;
    height: 300px;
  }
  
  .chart-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 24px;
  }
  
  .chart-percentage h3 {
    font-size: 32px;
    margin: 0;
  }
  
  .chart-percentage p {
    margin: 0;
    font-size: 16px;
    color: #666;
  }
  